.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.background {
  background-image: linear-gradient(180deg, #00000000 43%, #000000 70%), url('./components/assets/img/m2.jpg');
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  background-size: cover; /* Adjust this to your needs */
  background-repeat: no-repeat; /* Adjust this to your needs */
  width: 100%;
  height: 100vh; /* Adjust this to your needs */
  
}
.mobile-only {
  display: none;
}
.desktop-only {
  display: block;
}
.about-main{
  text-shadow: '2px 2px 7px rgba(0, 0, 0, 0.5)'
}
 .about-title{
margin-bottom: 20px;
 }
 .about-des{
  font-size: 25px !important;
 }
@media (max-width: 768px) {
  .background {
    background-size: contain; /* Adjust as needed */
    height: 33vh; /* Adjust as needed */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    
  }
  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none;
  }
  .about-title{
    margin-bottom: 0px;
    font-size: 20px;
     }
     .about-des{
      font-size: 18px !important;
     }
}
strong{
color: #FF8B03;
}
.pt-60{
margin-top: 60px;
}
.nav-link:hover, .nav-link:focus {
  color: #ffff !important;
  background-color: #392657 !important;
  border-radius: 25px;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: #ffff !important;
  background-color: #392657 !important;
  border-radius: 25px;
}
.nav-link {
  display: block;
  padding: 5px;
  font-size: 17px;
  font-weight: 700;
  margin:  0 5px;
  text-transform: uppercase;
  transition:none;}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #FF8B03;
  --bs-btn-border-color: #da7707;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c26a06;
  --bs-btn-hover-border-color: #c16905;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
